<template>
    <div v-if="new Array('Admin', 'SuperAdmin').includes(profile.role)">
        <v-row>
            <v-col cols="12" md="6" xl="4" class="pb-6">
                <v-card class="pa-3">
                    <v-card-title>
                        <v-icon>mdi-server-network</v-icon>&nbsp;
                        Selected Env
                    </v-card-title>
                    <v-card-text class="my-3">
                        <v-row>
                            <v-col cols="12" class="d-flex flex-row justify-center align-center">
                                <span class="d-flex flex-row align-center justify-start">
                                    <v-switch class="mx-0" inset color="warning" v-model="profile.envIsProd" @change="saveEnv"></v-switch>
                                    <b class="px-3 ma-0" v-text="profile.envIsProd ? 'Prod' : 'Dev'"></b>
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="pa-3 mt-5">
                    <v-card-title>
                        <v-icon>mdi-database-outline</v-icon>&nbsp;
                        Env backup
                    </v-card-title>
                    <v-card-text class="my-3">
                        <v-row>
                            <v-col cols="12">
                                <span>
                                    <v-file-input accept=".json,application/json" chips truncate-length="30"
                                        label="Backup file" v-model="backupFile"></v-file-input>
                                </span>
                                <span class="d-flex flex-row justify-center align-center">
                                    <v-btn class="ma-2" color="error" @click="loadDB">Load</v-btn>
                                    <v-btn class="ma-2" color="primary" @click="saveDB">Save</v-btn>
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="pa-3 mt-5">
                    <v-card-title>
                        <v-icon>mdi-broom</v-icon>&nbsp;
                        Clean logs of one user
                    </v-card-title>
                    <v-card-text class="my-3">
                        <v-row>
                            <v-col cols="12">
                                <span class="d-flex flex-row justify-center align-center">
                                    <v-text-field label="User ID" v-model="userId" />
                                    <v-btn class="ma-2" color="error" @click="cleanLog">Clean up</v-btn>
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" xl="8">
                <v-card class="pa-3">
                    <v-card-title>
                        <v-icon>mdi-sitemap-outline</v-icon>&nbsp;
                        List of DB collections
                    </v-card-title>
                    <v-card-text class="my-3">
                        <b class="success--text text-h6">Global collections</b>
                        <v-row class="mt-3">
                            <v-col class="pa-0" cols="12" sm="6" xl="4" v-for="(collection, i) in globalCollections" :key="i">
                                <v-btn x-small color="error" icon @click="deleteCollection(collection)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <b class="pl-3" v-text="collection.name"></b>
                            </v-col>
                        </v-row>
                        <v-divider class="my-8"></v-divider>
                        <b class="primary--text text-h6">Local collections</b>
                        <v-row class="mt-3">
                            <v-col class="pa-0" cols="12" sm="6" xl="4" v-for="(collection, i) in localCollections" :key="i">
                                <v-btn x-small color="error" icon @click="deleteCollection(collection)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <b class="pl-3" v-text="collection.name"></b>
                            </v-col>
                        </v-row>
                        <v-divider class="my-8"></v-divider>
                        <b class="error--text text-h6">Dev collections</b>
                        <v-row class="mt-3">
                            <v-col class="pa-0" cols="12" sm="6" xl="4" v-for="(collection, i) in devCollections" :key="i">
                                <v-btn x-small color="error" icon @click="deleteCollection(collection)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <b class="pl-3" v-text="collection.name"></b>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="pa-3 mt-5">
                    <v-card-title>
                        <v-icon>mdi-plus-network-outline</v-icon>&nbsp;
                        Add collection
                    </v-card-title>
                    <v-card-text class="my-3">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Collection name" v-model="newCollectionName" />
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn class="ma-2" color="success" @click="addGlobalCollection">Add global collection
                                </v-btn>
                                <v-btn class="ma-2" color="primary" @click="addLocalCollection">Add local collection
                                </v-btn>
                                <v-btn class="ma-2" color="error" @click="addDevCollection">Add dev collection</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import logger from "@/assets/functions/logger"
import { getFirestore, collection, addDoc, onSnapshot, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore"
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
    name: "DbTools",
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: {},
    setup() {
        return {
            db: getFirestore(),
        }
    },
    data() {
        return {
            newCollectionName: "",
            backupFile: null,
            userId: "",
            dbCollections: [],
            unsub: [],
        }
    },
    computed: {
        localCollections() {
            return this.dbCollections.filter(col => col.type == "local")
        },
        globalCollections() {
            return this.dbCollections.filter(col => col.type == "global")
        },
        devCollections() {
            return this.dbCollections.filter(col => col.type == "dev")
        },
    },
    async created() {

        let collectionsRef = collection(this.db, "dbCollections")

        this.unsub.push(
            onSnapshot(collectionsRef, (snapshot) => {
                this.dbCollections = []
                let tmp_collections = []
                snapshot.forEach((doc) => {
                    let tmp_collection = doc.data()
                    tmp_collection.id = doc.id
                    tmp_collections.push(tmp_collection)
                })
                this.dbCollections = tmp_collections
            })
        )
    },
    methods: {
        saveEnv(){
            console.log("saveEnv")
            let tmp_profile = this.profile
            tmp_profile.save()
        },
        cleanLog(){
            logger.cleanLog(this.userId)
        },
        deleteCollection(col) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteDoc(doc(this.db, "dbCollections", col.id))
                }
            })
        },
        addLocalCollection() {
            let colRef = collection(this.db, "dbCollections")
            addDoc(colRef, {
                name: this.newCollectionName,
                type: "local",
            }).then(() => {
                this.newCollectionName = ""
            })
        },
        addGlobalCollection() {
            let colRef = collection(this.db, "dbCollections")
            addDoc(colRef, {
                name: this.newCollectionName,
                type: "global",
            }).then(() => {
                this.newCollectionName = ""
            })
        },
        addDevCollection() {
            let colRef = collection(this.db, "dbCollections")
            addDoc(colRef, {
                name: this.newCollectionName,
                type: "dev",
            }).then(() => {
                this.newCollectionName = ""
            })
        },
        async copyProdToDev() {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        let prodPrefix = "env/prod/"
                        let devPrefix = "env/dev/"

                        console.log("Copying prod to dev...")

                        for (let col of this.localCollections) {
                            if (!this.globalCollections.includes(col) && !this.devCollections.includes(col)) {
                                let devColRef = collection(this.db, devPrefix + col.name)
                                let prodColRef = collection(this.db, prodPrefix + col.name)

                                let devSnapshot = await getDocs(devColRef)
                                let prodSnapshot = await getDocs(prodColRef)

                                console.log("start cleaning " + devPrefix + col.name)
                                devSnapshot.forEach(async (document) => {
                                    console.log("deleting " + document.id + " from " + devPrefix + col.name)
                                    deleteDoc(document)
                                })
                                console.log("finish cleaning " + devPrefix + col.name)

                                console.log("start copying " + prodPrefix + col.name + " to " + devPrefix + col.name)
                                prodSnapshot.forEach(async (document) => {
                                    console.log("copying " + document.id + " from " + prodPrefix + col.name)
                                    let docRef = doc(this.db, devPrefix + col.name, document.id)
                                    await setDoc(docRef, document.data())
                                })
                                console.log("finish copying " + prodPrefix + col.name + " to " + devPrefix + col.name)
                            }
                        }

                        console.log("COPY DONE")
                    }
                })
        },
        async copyDevToProd() {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        let prodPrefix = "env/prod/"
                        let devPrefix = "env/dev/"

                        console.log("Copying dev to prod...")

                        for (let col of this.localCollections) {
                            if (!this.globalCollections.includes(col) && !this.devCollections.includes(col)) {
                                let devColRef = collection(this.db, devPrefix + col.name)
                                let prodColRef = collection(this.db, prodPrefix + col.name)

                                let devSnapshot = await getDocs(devColRef)
                                let prodSnapshot = await getDocs(prodColRef)

                                console.log("start cleaning " + prodPrefix + col.name)
                                devSnapshot.forEach(async (document) => {
                                    console.log("deleting " + document.id + " from " + prodPrefix + col.name)
                                    deleteDoc(document)
                                })
                                console.log("finish cleaning " + prodPrefix + col.name)

                                console.log("start copying " + devPrefix + col.name + " to " + prodPrefix + col.name)
                                prodSnapshot.forEach(async (document) => {
                                    console.log("copying " + document.id + " from " + devPrefix + col.name)
                                    let docRef = doc(this.db, prodPrefix + col.name, document.id)
                                    await setDoc(docRef, document.data())
                                })
                                console.log("finish copying " + devPrefix + col.name + " to " + prodPrefix + col.name)
                            }
                        }

                        console.log("COPY DONE")
                    }
                })
        },
        async cleanEnv() {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            })
                .then(async (result) => {
                    if (result.isConfirmed) {

                        let colPrefixe = this.profile.envIsProd ? "env/prod/" : "env/dev/"

                        for (let col of this.localCollections) {
                            if (!this.globalCollections.includes(col) && !this.devCollections.includes(col)) {

                                let colRef = collection(this.db, colPrefixe + col.name)
                                let snapshot = await getDocs(colRef)

                                snapshot.forEach(document => {
                                    console.log("delete " + document.id + " in " + colPrefixe + col.name)
                                    let documentRef = doc(this.db, colPrefixe + col.name, document.id)
                                    deleteDoc(documentRef)
                                })
                            }
                        }
                        console.log("CLEANING DONE")
                    }
                })
        },
        async saveDB() {
            let dataBase = {
                data: {},
                info: {
                    type: "backup",
                    date: new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }),
                },
            }
            let catPrefixe = this.profile.envIsProd ? "env/prod/" : "env/dev/"


            for (let col of this.localCollections) {
                dataBase.data[col.name] = []
                let colRef = collection(this.db, catPrefixe + col.name)
                let snapshot = await getDocs(colRef)
                snapshot.forEach((doc) => {
                    let tmp_doc = doc.data()
                    tmp_doc.id = doc.id
                    dataBase.data[col.name].push(tmp_doc)
                })
            }
            for (let col of this.globalCollections) {
                dataBase.data[col.name] = []
                let colRef = collection(this.db, col.name)
                let snapshot = await getDocs(colRef)
                snapshot.forEach((doc) => {
                    let tmp_doc = doc.data()
                    tmp_doc.id = doc.id
                    dataBase.data[col.name].push(tmp_doc)
                })
            }
            for (let col of this.devCollections) {
                dataBase.data[col.name] = []
                let colRef = collection(this.db, col.name)
                let snapshot = await getDocs(colRef)
                snapshot.forEach((doc) => {
                    let tmp_doc = doc.data()
                    tmp_doc.id = doc.id
                    dataBase.data[col.name].push(tmp_doc)
                })
            }

            let dbStr = JSON.stringify(dataBase)
            let blob = new Blob([dbStr], { type: "application/json;charset=utf-8" })

            let dateStr = new Date().toISOString().slice(0, 10)

            let a = document.createElement("a")
            a.href = URL.createObjectURL(blob)
            a.download = "DB_" + dateStr + ".json"
            a.click()
            window.location.reload()
        },

        loadDB() {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.backupFile) {
                            let outputData = {}
                            let reader = new FileReader()
                            reader.onload = (e) => {
                                let data = e.target.result
                                outputData = JSON.parse(data)
                                this.restoreDB(outputData)
                            }
                            reader.readAsText(this.backupFile)
                        }
                    }
                })
        },

        async restoreDB(backupData) {
            if (backupData && backupData.info && backupData.data && backupData.info.type == "backup") {
                let catPrefixe = this.profile.envIsProd ? "env/prod/" : "env/dev/"
                for (let cat in backupData.data) {
                    let colName = catPrefixe + cat
                    console.log("start restoring : " + colName)

                    let colRef = collection(this.db, colName)
                    let response = await getDocs(colRef)


                    if (!this.devCollections.includes(cat)) {
                        console.log("Cleaning started")
                        if (!this.globalCollections.includes(cat)) {
                            response.forEach(document => {
                                console.log("delete " + document.id + " in " + colName)
                                let documentRef = doc(this.db, colName, document.id)
                                deleteDoc(documentRef)
                            })
                        }
                        console.log("Cleaning done")


                        console.log("Loading started")

                        if (!this.globalCollections.includes(cat)) {
                            for (let data of backupData.data[cat]) {
                                let docRef = doc(this.db, colName, data.id)
                                await setDoc(docRef, data)
                            }
                        } else {
                            for (let data of backupData.data[cat]) {
                                let docRef = doc(this.db, cat, data.id)
                                await setDoc(docRef, data)
                            }
                        }
                        console.log(colName + " fully restored")
                    }
                }
                console.log("RESTORE DONE !")
            } else {

                Swal.fire({
                    title: "Erreur",
                    text: "Le fichier de restauration est invalide",
                    icon: "error",
                    confirmButtonText: "OK",
                })
            }
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            unsub()
        })
    },
}
</script>
